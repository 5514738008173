<template lang="">
  <div class="row">
    <div class="col-9">
        <div class="card dveb-white-card tab-card">
          <b-tabs content-class="mt-3">
            <b-tab content-class="tab-content" title="Kullanıcı" active><User /></b-tab>
            <b-tab title="Yetki"> <Authority/></b-tab>
            <b-tab title="Dosyalar"><Files/></b-tab>
          </b-tabs>
        </div>
      
    </div>

    <div class="col-3 ">
      <div class="card dveb-gray-card aside-gray-card ">
        <div class="card-header">Yazılım Geliştirme</div>
        <div class="arrowItem "> <!--ORTALAMAK ICIN: d-flex align-items-center -->
            <div class="mr-3">
                <div class="circle">R</div>
            </div>
            <div class="userInfo ">
              <p class="userName"> Refik selim Altıok</p>
              <p class="userNum mt-2">(777) 777-77-77</p>
              <p class="userMail mt-2">refik@dveb.net</p>
              <p class="mt-2"><i  class="flaticon2-black-back-closed-envelope-shape"> </i> <i class="flaticon2-phone"></i></p>
            </div>
          </div>
          <button class="w-100 bg-danger text-white p-2 rounded-sm ">Sil</button>
          </div>
      </div>
  </div>
</template>
    </div>
  </div>
</template>
<script>
export default {
    components:{
        User: () => import("@/components/manage/users/details/User"),
        Authority: () => import("@/components/manage/users/details/Authority"),
        Files: ()=> import('@/components/manage/users/details/Files')
    }
};
</script>
<style lang="scss" >
.arrowItem{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.circle-name {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(43, 100, 214);
  color: white;
  text-align: center;
  line-height: 30px;
  margin-right: 15px;
}
.arrowItem {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}
.userInfo{
    width: 100%;
}
.userName{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 2px;
}
.userNum, .userMail{
    margin-bottom: 2px;
    font-size: 12px;
    color: #777777;
}
</style>
